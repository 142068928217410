@font-face {
  font-family: 'Passion One';
  src: url('./assets/fonts/PassionOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Viper Nora';
  src: url('./assets/fonts/viper_nora.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
