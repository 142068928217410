@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts';

html,
body {
  min-height: 100%;
  min-width: 100%;
  margin: 0;

  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: theme('colors.white');
  font-family: 'Fira Sans', sans-serif;
  scroll-behavior: smooth;
}

.title {
  font-family: 'Passion One', sans-serif;
}

.link {
  @apply transition-all;
  &:hover {
    filter: brightness(200%);
  }
}

h1 {
  @apply text-6xl;
}

h2 {
  @apply text-5xl;
}

h3 {
  @apply text-2xl;
}

.bevel {
  &--left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 75%);
  }
  &--right {
    clip-path: polygon(0 0, 100% 0, 100% 75%, 85% 100%, 0 100%);
  }
  &--top {
    clip-path: polygon(0 0, 75% 0, 100% 10%, 100% 100%, 0 100%);
  }
  &--top-sharp {
    clip-path: polygon(0 0, 85% 0, 100% 25%, 100% 100%, 0 100%);
  }
}

.text-shadow {
  text-shadow: 1px 1px 1px theme('colors.dark.DEFAULT');
}

a-button {
  min-width: 250px;
  display: block;
  width: fit-content;
}

th {
  font-weight: normal;
}

.animate-shake {
  animation: shake 8s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  1%,
  5% {
    transform: translate3d(-1px, 0, 0);
  }
  2%,
  6% {
    transform: translate3d(2px, 0, 0);
  }
  3%,
  7% {
    transform: translate3d(-4px, 0, 0);
  }
  4%,
  8% {
    transform: translate3d(4px, 0, 0);
  }
  9% {
    transform: translate3d(0, 0, 0);
  }
}
